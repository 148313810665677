import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBranchesAvailability,
  getLatestOrders,
} from "../../../store/selectors";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import Slider from "react-slick";
import { makeStyles } from "../../AppContainer/mui-theme";
import Image from "../../image";

import "../slick-theme.css";
import { get, isEmpty, take, find } from "lodash";
import { mediaTypes } from "../../../utils/media";
import NoImageRenderer from "../../no-image-renderer";
import { getParams, navigateTo, setParams } from "../../../utils/location";
import { setOrderForReorder as setOrderForReorderAction } from "../../../store/order/actions";
import { openCheckoutView } from "../../../store/app/actions";
import {
  loadBranchAvailabiltyIfNeeded,
  loadDeliveryAreas,
} from "../../../store/locations/actions";

import moment from "moment";
import localeDates from "../../../utils/localeDates";
import { Link } from "../../../utils/location";

const ReorderWidget = (props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const { appStyles, widget, pageContext } = props;
  const latestOrders = useSelector((state) => getLatestOrders(state, props));
  const branchesAvailability = useSelector((state) =>
    getBranchesAvailability(state, props)
  );

  const sliderRef = useRef(null);
  const dotsRef = useRef(null);

  useEffect(() => {
    dispatch(loadBranchAvailabiltyIfNeeded());
  }, []);

  let isLastDot = false;

  const settings = {
    infinite: false,
    speed: 1000,
    slidesToShow: 1.1, // to show a part of next slide
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    focusOnSelect: true,
    appendDots: (dots) => {
      // as slidesToShow is 1.1, we need to manually set active dot for last slide 
      if (isLastDot) {
        dotsRef.current &&
          (dotsRef.current.children[latestOrders.length - 1].className =
            "slick-active");
      } else {
        dotsRef.current &&
          (dotsRef.current.children[latestOrders.length - 1].className = "");
      }
      return (
        <div>
          <ul ref={dotsRef} style={{ margin: 0, padding: 0 }}>
            {" "}
            {dots}{" "}
          </ul>
        </div>
      );
    },
    beforeChange: (_, next) =>
      // next slide index for last is n.9, because of slidesToShow is 1.1
      (isLastDot = Math.round(next) === latestOrders.length - 1),
  };

  const setOrderForReorder = (order, firstAvailableDate) =>
    dispatch(
      setOrderForReorderAction(order, firstAvailableDate, pageContext.menuData)
    );

  const loadDeliveryAreasIfNeededAndStartReorder = (order) => () => {
    if (order.servingOption.needsAddress) {
      if (!order.deliveryAddress) {
        startReorder(order);
        navigateTo("/find-location");
      } else {
        const geoPoint = order.deliveryAddress.geoPoint;
        dispatch(
          loadDeliveryAreas({
            lat: geoPoint.latitude,
            lng: geoPoint.longitude,
            servingOptionId: order.servingOption.id,
            deliveryAddress: order.deliveryAddress,
          })
        );
      }
    } else {
      startReorder(order);
    }
  };

  const startReorder = (order) => {
    const branchAvailability = get(branchesAvailability, `${order.branchId}`);
    const hasTableCodes = get(order, "servingOption.hasTableCodes");
    if (branchAvailability && !hasTableCodes) {
      const firstAvailableDate =
        get(branchesAvailability, `${order.branchId}.availableFrom`) ||
        (get(branchesAvailability, `${order.branchId}.availability`) ===
          "AVAILABLE_NOW" &&
          new Date());

      setOrderForReorder(order, firstAvailableDate);

      dispatch(openCheckoutView());
      navigateTo(
        setParams("/order", { ...getParams(location), fromReorder: true })
      );
    } else {
      setOrderForReorder(order);
      const preSelectedBranchId = hasTableCodes && order.branchId;
      navigateTo(
        setParams("/find-location", {
          ...getParams(location),
          fromReorder: true,
          preSelectedBranchId,
        })
      );
    }
  };

  const renderCarusleMenuItem = (item, index) => {
    const { configuredMenuItemOrderData, sourceItem = {} } = item;

    const imageSizesProps = {
      sizes: "70px",
      wrapperStyle: {
        height: 70,
        width: 70,
      },
    };

    return (
      <div
        key={index}
        style={{
          height: "auto",
          margin: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {get(sourceItem, "imageKey") ? (
          <Image
            mediaKey={sourceItem.imageKey}
            mediaType={
              sourceItem.useCategoryImage
                ? mediaTypes.menuCategory
                : mediaTypes.menuItem
            }
            imagePreview={sourceItem.imagePreview}
            {...imageSizesProps}
          />
        ) : (
          <NoImageRenderer {...imageSizesProps} appStyles={props.appStyles} />
        )}
        <div
          style={{
            width: "80px",
            marginTop: 6,
            textAlign: "center",
            fontSize: "0.8rem",
          }}
        >
          {configuredMenuItemOrderData.itemName}
        </div>
      </div>
    );
  };

  const sliderContent = take(latestOrders, 5).map((order, index) => (
    <div style={{ height: "100%" }}>
      <Paper elevation={3} sx={{ margin: "10px" }}>
        <Box sx={{ padding: "10px" }}>
          <Typography style={{ margin: 0 }}>{`${
            order.servingOption.servingOptionTag
          } from ${get(
            find(pageContext.branches, ({ id }) => id === order.branchId),
            "name"
          )}`}</Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "left",
            }}
          >
            <Typography component={"span"} variant="caption">{`${
              order.orderItems.length
            } ${order.orderItems.length == 1 ? "item" : "items" } on ${moment(order.statusChangeDate).format(`D MMM YYYY`)} at ${moment(order.statusChangeDate).format(
                localeDates[appStyles.locale || "en-US"].hourFormat
              )}`}
            </Typography>  
          </Box>
          
          
        </Box>
        <Grid
          container
          sx={{ minHeight: "120px" }}
          display={"flex"}
          justifyContent={"space-between"}
        >
          {take(order.orderItems, 3).map((item) => (
            <Grid item>{renderCarusleMenuItem(item)}</Grid>
          ))}
        </Grid>
        <Divider sx={{ margin: "0 10px" }} />
        <Grid container sx={{ padding: "10px"}} display={"flex"}
            justifyContent={"right"}>
          
          <Grid
            item
            xs={4}
            display={"flex"}
            justifyContent={"right"}
            alignItems={"right"}
          >
            <Button
              variant="contained"
              onClick={loadDeliveryAreasIfNeededAndStartReorder(order)}
            >
              Reorder
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  ));

  return (
    <Card style={{ borderRadius: appStyles.cardBorderRadius }}>
        {(widget.title || widget.subtitle || widget.content) && <CardContent className={classes.content}>
          {!isEmpty(widget.title) && (
            <Typography gutterBottom variant="h2" component="h2">
              {widget.title}
            </Typography>
          )}
          {!isEmpty(widget.subtitle) && (
            <Typography gutterBottom variant="caption" component="h3">
              {widget.subtitle}
            </Typography>
          )}
          {!isEmpty(widget.content) && (
            <Typography variant="body1" component="p">
              {widget.content}
            </Typography>
          )}
        </CardContent>}
        <Slider ref={sliderRef} {...settings} className={classes.reorderSlider}>
          {sliderContent}
        </Slider>
    </Card>
  );
};

const desktopCardPromotionWidth = 376;

const useStyles = makeStyles()((theme) => ({
  reorderSlider: {
    height: `calc(100vw - ${theme.spacing(4)})`,
    width: `calc(100vw - ${theme.spacing(4)})`,
    [theme.breakpoints.up("sm")]: {
      height: "320px",
      width: `calc(${desktopCardPromotionWidth}px - ${theme.spacing(4)})`,
    },
  },
}));

export default ReorderWidget;
